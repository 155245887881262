import * as React from "react";
import {IMAGES} from "../../constants/IMAGES";
import {ButtonComponent} from "./ButtonComponent";
import useResponsive from "../../hooks/useResponsive";
import {MODALS} from "../layout/ModalContainer";
import {ModalContext} from "../../hooks/modalContext";

export interface IVehicleCard {
    brand: string
    model: string
    traction: string
    bluetooth: boolean
    ac: boolean
    doors: number
    chairs: number
    price: string
    image: string
    image_s: string
    carPlay?: boolean
    categories: string[]
}
const VehicleCardComponent = (prop: IVehicleCard) => {
    const {isMobile} = useResponsive()
    const {brand, ac, carPlay, categories, chairs, doors, image, model, price, traction, bluetooth, image_s} = prop
    const {handleModalData} = React.useContext(ModalContext);
    const setForVehicle = () => {
        handleModalData({
            showModal: true,
            extraData: {
                model
            },
            modal: MODALS.VEHICLE_MODAL,
            title: 'ALQUILAR TU VEHÍCULO',
            subtitle: `${brand} - ${model}`
        })
    }

    return <div className="flex-column width-100">
        <div className="width-100 bg-gray bg-lighten-4 radius-default flex-column vehicle-card-component">
            <div className="flex-row p-t p-b flex-middle flex-no-wrap">
                <div className="flex-column price-area p-l-lg line-height-small text-white">
                    <span>DESDE</span>
                    <span className="size-3 line-height-small primary-font strong text-primary">
                        ${price}
                    </span>
                    <span>El día</span>
                </div>
                <div className="flex-column p-l-lg">
                    <span className="text-uppercase line-height-small">{brand}</span>
                    <span className="strong line-height-small size-3 text-uppercase primary-font">{model}</span>
                </div>
            </div>
            <div className="flex-row p-b flex-middle flex-no-wrap">
                <div className="flex-column image-area line-height-small text-white">
                    <img width="100%" src={isMobile ? image_s :image} alt={`Alquiler ${brand} - ${model}`}/>
                </div>
                <div className="flex-column line-height-small">
                    <div className="flex-row flex-middle">
                        <span className="p-r-s"><img alt="Asientos" width={24} height={24} src={IMAGES.CHAIRS}/></span>
                        <span>{chairs} Asientos</span>
                    </div>
                    <div className="flex-row flex-middle">
                        <span className="p-r-s"><img alt="Puertas" width={24} height={24} src={IMAGES.DOOR}/></span>
                        <span>{doors} Puertas</span>
                    </div>
                    {ac &&
                        <div className="flex-row flex-middle flex-no-wrap">
                            <span className="p-r-s"><img alt="Puertas" width={24} height={24} src={IMAGES.AC}/></span>
                            <span>Aire Acondicionado</span>
                        </div>
                    }
                    {bluetooth &&
                        <div className="flex-row flex-middle flex-no-wrap">
                            <span className="p-r-s"><img alt="Puertas" width={24} height={24} src={IMAGES.BLUETOOTH}/></span>
                            <span>Bluetooth</span>
                        </div>
                    }
                    {carPlay &&
                        <div className="flex-row flex-middle flex-no-wrap">
                            <span className="p-r-s"><img alt="Puertas" width={24} height={24} src={IMAGES.BLUETOOTH}/></span>
                            <span>Apple CarPlay</span>
                        </div>
                    }
                    <div className="flex-row flex-middle flex-no-wrap">
                        <span className="p-r-s"><img alt="Puertas" width={24} height={24} src={IMAGES.TRACTION}/></span>
                        <span>Tracción {traction}</span>
                    </div>
                </div>
            </div>
        </div>
        <div className="flex-row flex-end p-t-s p-b">
            <ButtonComponent
                onClick={setForVehicle}
                buttonText={`Alquilar ${model}`}
                buttonType="border-primary"
                iconButton={
                    <span className="p-l-s flex-row flex-center flex-no-wrap">
                        <img src={IMAGES.WHATSAPP_LINE} height={20} width={20} alt="contactar por whatsapp"/>
                        {/*<img src={IMAGES.EMAIL_LINE} height={20} width={20} alt="contactar por email" className="m-l-s"/>*/}
                    </span>
                }
            />
        </div>
    </div>
}

export default VehicleCardComponent