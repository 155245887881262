import { IVehicleCard } from "../components/global/VehicleCardComponent";
import {IMAGES} from "../constants/IMAGES";

export const CATEGORY_LIST = {
    all: {
        name: 'todos',
        fullName: 'Todos los autos',
        icon: IMAGES.VEHICLES_ALL,
        iconP: IMAGES.VEHICLES_ALL_P,
        id: 'all'
    },
    sedan: {
        name: 'económicos',
            fullName: 'Autos Económicos',
        icon: IMAGES.VEHICLES_SEDAN,
        iconP: IMAGES.VEHICLES_SEDAN_P,
        id: 'sedan'
    },
    van: {
        name: 'van',
        fullName: 'Vans',
        icon: IMAGES.VEHICLES_VAN,
        iconP: IMAGES.VEHICLES_VAN_P,
        id: 'van'
    },
    suv: {
        name: 'suv',
        fullName: 'SUV´s',
        icon: IMAGES.VEHICLES_SUV,
        iconP: IMAGES.VEHICLES_SUV_P,
        id: 'suv'
    },
    high: {
        name: 'gama alta',
        fullName: 'Autos de gama alta',
        icon: IMAGES.VEHICLES_HIGH,
        iconP: IMAGES.VEHICLES_HIGH_P,
        id: 'high'
    },
    trunk: {
        name: 'camionetas',
        fullName: 'Camionetas',
        icon: IMAGES.VEHICLES_TRUNK,
        iconP: IMAGES.VEHICLES_TRUNK_P,
        id: 'trunk'
    }
}

export const CATEGORIES = Object.values(CATEGORY_LIST)

const BRANDS = {
    HYUNDAI: 'Hyundai',
    RENAULT: 'Renault',
    CHEVROLET: 'Chevrolet',
    TOYOTA: 'Toyota',
    GAC: 'GAC',
    CHERY: 'Chery',
    JAC: 'JAC',
    SUZUKI: 'SUZUKI',
    KIA: 'KIA',
    CITROEN: 'Citroen',
    SHINERAY: 'shineray',
}

const TRACTION = {
    MANUAL: 'Manual',
    AUTOMATIC: 'Automática'
}

export const VEHICLES: Array<IVehicleCard> = [
    {
        brand: BRANDS.HYUNDAI,
        model: 'Grand I10',
        traction: TRACTION.MANUAL,
        bluetooth: true,
        ac: true,
        doors: 5,
        chairs: 5,
        price: '50.00',
        image: require('../assets/models/autos/grani10.png'),
        image_s: require('../assets/models/autos/grani10_s.png'),
        categories: [CATEGORY_LIST.sedan.id]
    },
    {
        brand: BRANDS.CHEVROLET,
        model: 'Sail',
        traction: TRACTION.MANUAL,
        bluetooth: true,
        ac: true,
        doors: 5,
        chairs: 5,
        price: '50.00',
        image: require('../assets/models/autos/sail.png'),
        image_s: require('../assets/models/autos/sail_s.png'),
        categories: [CATEGORY_LIST.sedan.id]
    },
    {
        brand: BRANDS.KIA,
        model: 'Kia soluto',
        traction: TRACTION.MANUAL,
        bluetooth: true,
        ac: true,
        doors: 5,
        chairs: 5,
        price: '50.00',
        image: require('../assets/models/autos/soluto.png'),
        image_s: require('../assets/models/autos/soluto_s.png'),
        categories: [CATEGORY_LIST.sedan.id]
    },
    {
        brand: BRANDS.CITROEN,
        model: 'C Elysee',
        traction: TRACTION.MANUAL,
        bluetooth: true,
        ac: true,
        doors: 5,
        chairs: 5,
        price: '50.00',
        image: require('../assets/models/autos/c_elysee.png'),
        image_s: require('../assets/models/autos/c_elysee_s.png'),
        categories: [CATEGORY_LIST.sedan.id]
    },
    {
        brand: BRANDS.SUZUKI,
        model: 'Swift Híbrido',
        traction: TRACTION.MANUAL,
        bluetooth: true,
        ac: true,
        doors: 5,
        chairs: 5,
        price: '55.00',
        image: require('../assets/models/autos/swift_hibrido.png'),
        image_s: require('../assets/models/autos/swift_hibrido_s.png'),
        categories: [CATEGORY_LIST.sedan.id]
    },
    {
        brand: BRANDS.KIA,
        model: 'Río',
        traction: TRACTION.AUTOMATIC,
        bluetooth: false,
        ac: true,
        doors: 5,
        chairs: 5,
        price: '60.00',
        image: require('../assets/models/autos/rio.png'),
        image_s: require('../assets/models/autos/rio_s.png'),
        categories: [CATEGORY_LIST.sedan.id]
    },
    {
        brand: BRANDS.CHEVROLET,
        model: 'Onix Turbo',
        traction: TRACTION.AUTOMATIC,
        bluetooth: false,
        ac: true,
        doors: 5,
        chairs: 5,
        price: '60.00',
        carPlay: true,
        image: require('../assets/models/autos/onix_turbo.png'),
        image_s: require('../assets/models/autos/onix_turbo_s.png'),
        categories: [CATEGORY_LIST.sedan.id]
    },
    {
        brand: BRANDS.CHEVROLET,
        model: 'DMAX 4x4 Turbo Diesel',
        traction: TRACTION.MANUAL,
        bluetooth: false,
        ac: true,
        doors: 4,
        chairs: 5,
        price: '70.00',
        image: require('../assets/models/camionetas/dmax4x4.png'),
        image_s: require('../assets/models/camionetas/dmax4x4_s.png'),
        categories: [CATEGORY_LIST.trunk.id]
    },
    {
        brand: BRANDS.JAC,
        model: 'T6 Turbo Diesel',
        traction: TRACTION.MANUAL,
        bluetooth: false,
        ac: true,
        doors: 4,
        chairs: 5,
        price: '80.00',
        image: require('../assets/models/camionetas/t6gac.png'),
        image_s: require('../assets/models/camionetas/t6gac_s.png'),
        categories: [CATEGORY_LIST.trunk.id]
    },
    {
        brand: BRANDS.RENAULT,
        model: 'Stepway',
        traction: TRACTION.AUTOMATIC,
        bluetooth: false,
        ac: true,
        doors: 5,
        chairs: 5,
        price: '65.00',
        image: require('../assets/models/suv/stepway.png'),
        image_s: require('../assets/models/suv/stepway_s.png'),
        categories: [CATEGORY_LIST.suv.id]
    },
    {
        brand: BRANDS.SUZUKI,
        model: 'SZ',
        traction: TRACTION.AUTOMATIC,
        bluetooth: false,
        ac: true,
        doors: 5,
        chairs: 5,
        price: '75.00',
        image: require('../assets/models/suv/grandvitarasz.png'),
        image_s: require('../assets/models/suv/grandvitarasz_s.png'),
        categories: [CATEGORY_LIST.suv.id]
    },
    {
        brand: BRANDS.GAC,
        model: 'All New GS4',
        traction: TRACTION.AUTOMATIC,
        bluetooth: false,
        ac: true,
        doors: 5,
        chairs: 5,
        price: '100.00',
        image: require('../assets/models/suv/gs4.png'),
        image_s: require('../assets/models/suv/gs4_s.png'),
        categories: [CATEGORY_LIST.suv.id]
    },
    {
        brand: BRANDS.CHERY,
        model: 'Mini Van',
        traction: TRACTION.MANUAL,
        bluetooth: false,
        ac: true,
        doors: 5,
        chairs: 11,
        price: '90.00',
        image: require('../assets/models/van/minivan.png'),
        image_s: require('../assets/models/van/minivan_s.png'),
        categories: [CATEGORY_LIST.van.id]
    },
    {
        brand: BRANDS.SHINERAY,
        model: 'X30 L',
        traction: TRACTION.MANUAL,
        bluetooth: true,
        ac: true,
        doors: 5,
        chairs: 11,
        price: '110.00',
        image: require('../assets/models/van/shineray_x30_l.png'),
        image_s: require('../assets/models/van/shineray_x30_l_s.png'),
        categories: [CATEGORY_LIST.van.id]
    },
    {
        brand: BRANDS.HYUNDAI,
        model: 'H1',
        traction: TRACTION.MANUAL,
        bluetooth: false,
        ac: true,
        doors: 5,
        chairs: 12,
        price: '125.00',
        image: require('../assets/models/van/h1.png'),
        image_s: require('../assets/models/van/h1_s.png'),
        categories: [CATEGORY_LIST.van.id]
    },
    {
        brand: BRANDS.TOYOTA,
        model: 'New Fortuner 4x4',
        traction: TRACTION.AUTOMATIC,
        bluetooth: false,
        ac: true,
        doors: 5,
        chairs: 7,
        price: '150.00',
        image: require('../assets/models/high/fortuner.png'),
        image_s: require('../assets/models/high/fortuner_s.png'),
        categories: [CATEGORY_LIST.high.id]
    },
]